import { Component } from '@angular/core';
import { BlogPost } from './models/blog-post.model';

@Component({
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.scss']
})
export class BlogComponent {
    blogPosts: BlogPost[] = [
        {
            id: '1',
            description: 'Продвижение в инстаграм очень сложное!',
            url: 'instagram',
            date: '03.11.2023',
            href: '/blog/instagram',
            title: 'Инстаграм продвижение',
            image: 'https://mdbootstrap.com/img/new/slides/041.webp'
        },
        {
            id: '2',
            description: 'Продвижение в инстаграм очень сложное!',
            url: 'instagram',
            date: '02.11.2023',
            href: '/blog/instagram',
            title: 'Инстаграм продвижение',
            image: 'https://mdbootstrap.com/img/new/slides/041.webp'
        },
        {
            id: '3',
            description: 'Продвижение в инстаграм очень сложное!',
            url: 'instagram',
            date: '01.11.2023',
            href: '/blog/instagram',
            title: 'Инстаграм продвижение',
            image: 'https://mdbootstrap.com/img/new/slides/041.webp'
        }
    ]
}
