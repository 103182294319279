import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { AlertService } from '@core/services/alert.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

    emailForm: FormGroup;
    isSubmitted = false;

    get email(): FormControl {
        return this.getFormControl('email');
    }

    get isSubmitButtonDisabled(): boolean {
        if (this.isSubmitted && this.emailForm.invalid && (this.emailForm.dirty || this.emailForm.touched)) {
            return true;
        }

        return false;
    }

    constructor(private http: HttpClient, private alertService: AlertService) {
        this.emailForm = new FormGroup({
            email: new FormControl(null, [Validators.email])
        });
    }

    onSubmit() {
        if (this.emailForm.invalid) {
            this.isSubmitted = true;
            return;
        }

        this.http.post('https://assets.mailerlite.com/jsonp/696584/forms/105287086475577307/subscribe', { 
            fields : {email: this.email.value}
        }).subscribe(data =>{
            this.emailForm.reset();
            this.alertService.showSuccess('Вы успешно подписались на рассылку!');
        });
    }

    private getFormControl(controlName: string): FormControl {
        return this.emailForm.get(controlName) as FormControl;
    }
}
