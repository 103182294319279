<footer class="bg-light text-center">
    <div class="container p-4 pb-0">        
        <section class="email">
            <form [formGroup]="emailForm" (ngSubmit)="onSubmit()">
                <div class="row d-flex justify-content-center">
                    <div class="col-auto">
                        <p class="pt-2">
                            <strong>Подпишись на мою рассылку</strong>
                        </p>
                    </div>

                    <div class="col-md-5 col-12 mb-4 email-input">
                        <mdb-form-control>
                            <input
                                mdbInput
                                id="emailInput"
                                aria-label="email"
                                aria-required="true"
                                type="email"
                                class="form-control"
                                data-inputmask=""
                                name="fields[email]"
                                placeholder="Email"
                                autocomplete="email"
                                formControlName="email">
                            <!-- <input mdbInput type="email" id="emailInput" class="form-control" /> -->
                            <label mdbLabel class="form-label" for="emailInput">Email</label>
                        </mdb-form-control>
                        <div *ngIf="isSubmitButtonDisabled">
                            <div class="validation-error">The email is not valid</div>
                        </div>
                    </div>
                    
                    <div class="col-auto">
                        <button mdbRipple type="submit" class="btn btn-primary mb-4" [disabled]="isSubmitButtonDisabled">
                            Подписаться
                        </button>
                    </div>
                </div>
            </form>
        </section>
    </div>

    <div class="social-links text-center p-3" style="background-color: rgba(0, 0, 0, 0.2);">
        <section>
            <a target="_blank" href="https://t.me/chatviyatskevich">
                <img lazy
                    src="/assets/images/telegram.svg"
                    class="footer-image"
                    alt="Telegram"
                    width="33"
                    height="30" />
            </a>

            <a target="_blank" href="https://www.instagram.com/vi.yatskevich">
                <img lazy
                    src="/assets/images/instagram.svg"
                    class="footer-image"
                    alt="Telegram"
                    width="33"
                    height="30" />
            </a>

            <a target="_blank" href="https://www.facebook.com/profile.php?id=100029856918179">
                <img lazy
                    src="/assets/images/facebook.svg"
                    class="footer-image"
                    alt="Facebook"
                    width="33"
                    height="30" />
            </a>
        </section>
    </div>
</footer>