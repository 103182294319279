import { Inject, Injectable, Injector } from '@angular/core';

import { GlobalConfig, ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    private defaultAlertConfig: Partial<GlobalConfig> = {
        closeButton: true,
        enableHtml: true,
        positionClass: 'toast-top-center',
        preventDuplicates: true,
        tapToDismiss: false,
        timeOut: 5000,
        onActivateTick: true
    };

    get toastrService(): ToastrService {
        return this.injector.get<ToastrService>(ToastrService);
    }

    constructor(@Inject(Injector) private injector: Injector) {}

    showError(message: string, title = ''): void {
        this.toastrService.error(message, title, this.defaultAlertConfig);
    }

    showInfo(message: string, title = ''): void {
        this.toastrService.info(message, title, this.defaultAlertConfig);
    }

    showSuccess(message: string, title = ''): void {
        this.toastrService.success(message, title, this.defaultAlertConfig);
    }

    showWarning(message: string, title = ''): void {
        this.toastrService.warning(message, title, this.defaultAlertConfig);
    }
}
