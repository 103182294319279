<header>
    <nav class="navbar navbar-expand-lg fixed-top navbar-scroll scrolling-navbar"
        [ngClass]="{'navbar-scrolled' : scrolled}">
        <div class="container-fluid">
            <button
                    class="navbar-toggler ps-0"
                    type="button"
                    (click)="externalContent.toggle()"
                    aria-controls="navbarToggleExternalContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    >
                <span class="d-flex justify-content-start align-items-center">
                    <i class="fas fa-bars"></i>
                </span>
            </button>
          
            <div
                class="collapse navbar-collapse justify-content-between"
                id="navbarToggleExternalContent"
                mdbCollapse
                #externalContent="mdbCollapse">
                <ul class="navbar-nav"></ul>
                <ul class="navbar-nav mb-2 mb-lg-0 menu-items">
                    <li class="nav-item active">
                        <a class="nav-link navbar-brand" aria-current="page" href="#intro">ГЛАВНАЯ</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link navbar-brand" aria-current="page" href="#intro">ГАЙД</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link navbar-brand" aria-current="page" [routerLink]="['/blog']">БЛОГ</a>
                    </li>
                </ul>

                <ul class="navbar-nav flex-row">
                  <!-- Icons -->
                    <li class="nav-item">
                        <a
                          class="nav-link pe-2"
                          href="https://t.me/chatviyatskevich"
                          rel="nofollow"
                          target="_blank">
                            <i class="fab fa-telegram"></i>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a
                          class="nav-link px-2"
                          href="https://www.facebook.com/profile.php?id=100029856918179"
                          rel="nofollow"
                          target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a
                          class="nav-link px-2"
                          href="https://www.instagram.com/vi.yatskevich"
                          rel="nofollow"
                          target="_blank"
                          >
                            <i class="fab fa-instagram"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <!-- Animated navbar -->
  
    <!-- Background image -->
    <div
         id="intro"
         class="bg-image"
         style="
                background-image: url(https://mdbcdn.b-cdn.net/img/new/fluid/city/113.jpg);
                height: 100vh;
                "
         >
        <div class="mask text-white" style="background-color: rgba(0, 0, 0, 0.8)">
            <div class="container d-flex align-items-center text-center h-100">
            <div>
                <h1 class="mb-5">Название</h1>
                <p>
                Контент
                </p>
            </div>
            </div>
        </div>
    </div>
    <!-- Background image -->
</header>