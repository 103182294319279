<div class="blog-container container my-5">
    <div class="row">
        <div class="blog-post col-lg-4 col-md-6 col-sm-12 mb-4" *ngFor="let blogPost of blogPosts">
            <a [routerLink]="blogPost.url">
                <div class="bg-image hover-zoom blog-image">
                    <img [src]="blogPost.image" alt="blog-image" />
                </div>
                <h5 class="mt-3">{{blogPost.title}}</h5>
                <p class="mt-3">{{blogPost.description}}</p>
            </a>

            <a class="detail-button" [routerLink]="blogPost.url">
                <span>Подробнее</span>
                <i class="fas fa-arrow-right"></i>
            </a>
        </div>
    </div>
</div>