<div class="page-wrapper">
  <div class="header-wrapper">
      <app-header></app-header>
  </div>
  <main class="content-wrapper">
      <router-outlet></router-outlet>
  </main>
  <div class="footer-wrapper">
    <app-footer></app-footer>
  </div>
</div>
